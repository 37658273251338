<template>
  <v-app-bar
    id="app-bar"
    dense
    app
    clipped-left
    height="75"
  >
  <!-- color="transparent" -->
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="drawer">
        mdi-dots-vertical
      </v-icon>

      <v-icon v-else>
        mdi-menu
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-bold text-h6"
      v-text="$route.name"
    />

    <v-spacer />

    <!-- <v-text-field
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn v-bind="attrs" v-on="on" elevation="0" icon class="ml-2 mr-2">
          <v-avatar size="35" color="primary">
            <span
              class="white--text text-h6"
              >{{
                    usuario.substr(0, 1)
                  }}</span
            >
          </v-avatar>
        </v-btn>
      </template>

      <v-list dense >
        <template>
          <div align="center" class="pa-2">
            <v-badge
              bordered
              bottom
              color="green"
              dot
              offset-x="10"
              offset-y="10"
              class="mb-4"
            >
              <v-avatar size="60" color="primary">
                <span
                  class="white--text text-h6"
                  >{{
                    usuario.toUpperCase().substr(0, 1)
                  }}</span
                >
              </v-avatar>
            </v-badge>
            <h3>
             {{usuario}}
            </h3>
            <v-card color="deep-black" dark class="mt-2 pa-2">
              <div class="text-subtitle-2 text-center">Administrador</div>
            </v-card>
          </div>
        </template>
        <v-list-item-content class="justify-center">
          <v-divider></v-divider>
          <v-btn depressed rounded text @click="logout">
             Cerrar Sesion <v-icon>mdi-close</v-icon></v-btn>
        </v-list-item-content>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    components: {
    },
    mounted(){

    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
data() {
    return {
      usuario: localStorage.getItem("usuario"),
    };
  },

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      logout() {
      this.$store.dispatch("destroyToken").then(
        (response) => {
          if(response)
            this.$router.push({ name: "Login" });
        }
      );
    },
    },
  }
</script>
